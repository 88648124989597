<template>
    <app-dialog-info
        class="app-dialog-book-appointment"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">{{ heading }}</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <app-error v-model="errors.show" :message="errors.message" />

            <p>Please, choose booking time from the list below</p>

            <div class="form-group">
                <div class="form-controls">
                    <app-dropdown-select
                        v-model="booking_time_id"
                        :options="available_times"

                        key-value="value"
                        key-title="title"

                        label="Available times"

                        :error="errors.fields.booking_time_id"

                        @change="errors.fields.booking_time_id = null"
                    />
                </div>
            </div>

            <div class="btns">
                <button class="btn btn-primary" @click="confirm">Confirm</button>
                <button class="btn btn-primary btn-cancel" @click="close">Cancel</button>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        value:    { required: true,                        },
        maxWidth: { type: [Number, String], default: 610   },

        uuid:    { type: String, default: null },
        mode:    { type: String, default: null },
        heading: { type: String, default: null },

        availableTimes: { type: Array, default: () => [] },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appDropdownSelect,
    },

    data() {
        return {
            booking_time_id: null,

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    methods: {
        validation() {
            let is_valid = true

            this.errors.fields = {}

            if (!this.booking_time_id) {
                this.errors.fields.booking_time_id = 'Please, choose booking time'
                is_valid = false
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    SPID: this.current_spid,
                    UUID: this.uuid,
                    Mode: this.mode,

                    StartTimeNanos: this.available_times[this.booking_time_id].StartTimeNanos,
                    EndTimeNanos: this.available_times[this.booking_time_id].EndTimeNanos,
                }

                /**
                 * @todo
                 */
                this.$store.dispatch('api_ufbprod/BookAppointment', params)
                    .then(() => {
                        this.$emit('confirm')

                        this.loading = false

                        this.$store.dispatch('addToast', {
                            message: 'Booking was successful',
                            delay: 5000,
                        })
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },

        close() {
            this.$emit('close')
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },

        available_times() {
            const available_times = {}

            for (let i = 0, len = this.availableTimes.length; i < len; i++) {
                const id = i + 1

                const StartTimeNanos = +this.availableTimes[i].StartTimeNanos
                const EndTimeNanos = +this.availableTimes[i].EndTimeNanos

                available_times[id] = {
                    value: id,
                    title: `${formatDateNano(StartTimeNanos)} - ${formatDateNano(EndTimeNanos)}`,

                    StartTimeNanos,
                    EndTimeNanos,
                }
            }

            return available_times
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-book-appointment {
    .app-dialog-body {
        p {
            margin-bottom: 24px;

            font-weight: normal;
        }

        .form-group {
            width: 100%;
        }

        .btns {
            margin-top: 30px;
        }
    }
}
</style>