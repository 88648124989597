<template>
    <app-dialog-info
        class="app-dialog-state-update"
        v-model="show"
        v-if="show"
        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Update State</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <div class="wrap">
                <app-error v-model="error" :message="error" />

                <div class="content">
                    <p>Select a new state to put this manually processed order into:</p>
                </div>

                <div class="form-group">
                    <div class="form-controls">
                        <app-textarea
                            v-model="note"

                            label="Write a note..."

                            :maxlength="500"
                            :characters-counter="true"

                            :required="true"
                            :error="errors.fields.note"

                            @change="errors.fields.note = null"
                        />
                    </div>

                    <div class="form-controls">
                        <app-dropdown-select
                            v-model="state"

                            label="Choose New State"

                            key-value="value"
                            key-title="title"

                            :options="state_options"
                            :error="errors.fields.state"

                            @change="errors.fields.state = null"
                        />
                    </div>
                </div>

                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="confirm">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTextarea from '@/components/app-textarea'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:         { required: true                       },
        maxWidth:      { type: [Number, String], default: 610 },
        uuid:          { required: true                       },
        spid:          { required: true                       },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appTextarea,
        appDropdownSelect,
    },

    data() {
        return {
            loading: false,

            note: null,

            state: null,
            UFBProduct: null,

            error: null,

            errors: {
                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            console.log('init() {')

            this.loading = true

            const params = {
                UUID: this.uuid,
                SPID: this.spid,
            }

            console.log('params', params)

            this.$store.dispatch('api_ufbprod/GetUFBOrderByUUID', params)
                .then(response => {
                    console.log('response', JSON.parse(JSON.stringify(response)))

                    this.state = response.State
                    this.UFBProduct = response

                    this.loading = false
                })
                .catch(error => {
                    this.error = errMessage(error)

                    this.loading = false
                })
        },

        validation() {
            this.errors.fields = {}

            let is_valid = true

            const error = typeof this.note == 'string' && this.note.trim().length
                ? null
                : 'Please, write a note'

            if (error) {
                is_valid = false
                this.errors.fields.note = error
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                this.loading = true

                this.error = null

                const promises = [
                    this.UpdateUFBOrder(),

                    this.AddUFBOrderNote(),
                ]

                Promise.all(promises)
                    .then(() => {
                        this.$emit('confirm')

                        this.loading = false

                        this.close()
                    })
                    .catch(error => {
                        this.error = errMessage(error)

                        this.loading = false
                    })
            }
        },

        UpdateUFBOrder() {
            console.log('UpdateUFBOrder() {')

            let State = this.state
            let Substate = ''

            if (State == 'RFS Confirmed' || State == 'Service Given') {
                Substate = State
                State = 'InProgress'
            }

            const params = {
                ...this.UFBProduct,

                State,
                Substate,
            }

            console.log('params', params)

            return this.$store.dispatch('api_ufbprod/UpdateUFBOrder', params)
        },

        AddUFBOrderNote() {
            console.log('AddUFBOrderNote() {')

            const params = {
                UUID: this.uuid,
                SPID: this.spid,
                NoteText: this.note.trim(),
                AddedBy: this.added_by,
            }

            console.log('params', params)

            return this.$store.dispatch('api_ufbprod/AddUFBOrderNote', params)
        },

        close() {
            this.$emit('close')
        },
    },

    computed: {
        ...mapGetters([
            'id_first_name',
            'id_last_name',
            'id_mobile',
        ]),

        added_by() {
            return `${ this.id_first_name } ${ this.id_last_name } (${ this.id_mobile })`
        },

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },

        state_options() {
            const state_options = [
                'Acknowledged',
                'On Hold',
                'In Progress',
                'RFS Confirmed',
                'Service Given',
                'Completed',
                'Pending Cancellation',
                'Cancelled',
            ]

            return state_options.map(option => ({
                value: option,
                title: option,
            }))
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-state-update {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 24px;
        }

        .form-group {
            .form-controls {
                margin-bottom: 24px;

                .app-textarea {
                    height: 144px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-state-update {
        .app-dialog-body {
            .content {
                margin-bottom: 16px;
            }

            .form-group {
                .form-controls {
                    margin-bottom: 16px;
                }
            }

            .btns {
                padding: 0;
            }
        }
    }
}
</style>