<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <order-details v-if="sidebar.order_details"
                    :uuid="service_uuid"

                    @close-sidebar="closeSidebar"
                />

                <order-notes v-else-if="sidebar.order_notes"
                    :uuid="service_uuid"

                    ref="order_notes"

                    @close-sidebar="closeSidebar"
                />
            </app-sidebar-details>
        </template>

        <div class="page-fibre-orders container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Fibre Orders</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <app-error v-model="errors.show" :message="errors.message" />

                    <app-table
                        class="fibre-orders-table"
                        :class="{'with-attention': with_attention}"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="orders"

                        :selected-row="service_uuid"

                        :loading="loading"

                        @toggle-order-details="onToggleOrderDetailsSidebar"
                        @toggle-order-notes="onToggleOrderNotesSidebar"
                        @update-state="onUpdateState"
                        @book-appointment="showBookAppointmentDialog"

                        @cancel-order="onCancelOrder"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>

        <app-dialog-cancel-order
            v-if="dialogs.devices.remove.show"
            v-model="dialogs.devices.remove.show"

            :uuid="dialogs.devices.remove.uuid"
            :spid="this.current_spid"

            @close="onCancelOrderClose"
            @confirm="onCancelOrderConfirm"
        />

        <app-dialog-state-update
            v-if="dialogs.devices.update.show"
            v-model="dialogs.devices.update.show"

            :uuid="dialogs.devices.update.uuid"
            :spid="dialogs.devices.update.spid"

            @close="onUpdateStateClose"
            @confirm="onUpdateStateConfirm"
        />

        <app-dialog-book-appointment
            v-if="dialogs.book_appointment.show"
            v-model="dialogs.book_appointment.show"

            :uuid="dialogs.book_appointment.uuid"
            :mode="dialogs.book_appointment.mode"
            :heading="dialogs.book_appointment.title"

            :available-times="dialogs.book_appointment.available_times"

            @close="onBookAppointmentDialogClose"
            @confirm="onBookAppointmentDialogConfirm"
        />
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import orderDetails from '@/views/fibre/order-details'
import orderNotes from '@/views/fibre/order-notes'

import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appError from '@/components/app-error'

import appDialogStateUpdate from './components/app-dialog-state-update'
import appDialogCancelOrder from './components/app-dialog-cancel-order'
import appDialogBookAppointment from './components/app-dialog-book-appointment'

import formatDateNano from '@/helpers/format-date-nano'
import errMessage from '@/helpers/errMessage'
import randomInteger from '@/helpers/random-integer'

const CONFIG = {
    RequiresNewRFSTime: {
        state: 'RequiresNewRFSTime',
        title: 'Book RFS',
        display: 'Book RFS',
        mode: 'RFS',
    },

    RequiresSiteVisitScopeBooking: {
        state: 'RequiresSiteVisitScopeBooking',
        title: 'Book Scoping Visit',
        display: 'Book Scoping Visit',
        mode: 'SiteVisitScope',
    },

    RequiresSiteVisitInstallBooking: {
        state: 'RequiresSiteVisitInstallBooking',
        title: 'Book Install Visit',
        display: 'Book Install Visit',
        mode: 'SiteVisitInstall',
    },

    RequiresSiteVisitInstallCSEBooking: {
        state: 'RequiresSiteVisitInstallCSEBooking',
        title: 'Book Install & CSE',
        display: 'Book Install & CSE',
        mode: 'SiteVisitInstallCSE',
    },

    RequiresSiteVisitCSEBooking: {
        state: 'RequiresSiteVisitCSEBooking',
        title: 'Book CSE Visit',
        display: 'Book CSE Visit',
        mode: 'SiteVisitCSE',
    },

    RequiresSiteVisitChangeONTBooking: {
        state: 'RequiresSiteVisitChangeONTBooking',
        title: 'Book Change ONT Visit',
        display: 'Book Change ONT Visit',
        mode: 'SiteVisitChangeONT',
    },

    RequiresSiteVisitReplaceONTBooking: {
        state: 'RequiresSiteVisitReplaceONTBooking',
        title: 'Book Site Visit',
        display: 'Book Site Visit',
        mode: 'SiteVisitReplaceONT',
    },
}

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        orderDetails,
        orderNotes,

        appTable,
        appPagination,
        appError,

        appDialogStateUpdate,
        appDialogCancelOrder,
        appDialogBookAppointment,
    },

    data() {
        return {
            loading: false,

            filter: {
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc'
                },
            },

            sort: [
                { field: 'UUID',           title: 'Order ID'   },
                { field: 'Address',        title: 'Address'    },
                { field: 'CreatedAtNanos', title: 'Created At' },
                { field: 'State',          title: 'State'      },
            ],

            with_attention: false,

            orders: [],

            service_uuid: null,

            is_mobile_mode: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                devices: {
                    remove: {
                        show: false,
                        uuid: null,
                        spid: null,
                    },

                    update: {
                        show: false,
                        uuid: null,
                        spid: null,
                    },
                },

                book_appointment: {
                    show: false,

                    uuid: null,
                    mode: null,
                    title: null,

                    available_times: [],
                },
            },

            sidebar: {
                order_details: false,
                order_notes:   false,
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getFibreOrders()
        },

        handleWindowResize() {
            this.is_mobile_mode = document.body.clientWidth <= this.$mobile_size
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getFibreOrders()
        },

        onPageChange(page) {
            this.getFibreOrders(page)
        },

        onBookAppointmentDialogClose() {
            this.dialogs.book_appointment.uuid = null
            this.dialogs.book_appointment.mode = null
            this.dialogs.book_appointment.title = null

            this.dialogs.book_appointment.available_times = []

            this.dialogs.book_appointment.show = false
        },
        onBookAppointmentDialogConfirm() {
            this.onBookAppointmentDialogClose()

            this.getFibreOrders()
        },
        showBookAppointmentDialog(row) {
            this.loading = true

            const SPID = this.current_spid
            const UUID = row.uuid
            const Mode = row.book_appointment_data.mode

            const params = {
                SPID,
                UUID,
                Mode,
            }

            this.$store.dispatch('api_ufbprod/GetPossibleTimes', params)
                .then(({ UUID, StartEnd }) => {
                    this.dialogs.book_appointment.uuid = UUID
                    this.dialogs.book_appointment.mode = Mode
                    this.dialogs.book_appointment.title = row.book_appointment_data.title

                    this.dialogs.book_appointment.available_times = StartEnd

                    this.dialogs.book_appointment.show = true

                    this.loading = false
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.loading = false
                })
        },

        getFibreOrders(page) {
            this.loading = true

            const filter = {
                ...this.filter,
            }

            const params = {
                'SPID': this.current_spid,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_ufbprod/GetUFBOrdersPaginated', params)
                .then(({ PageInfo, UFBOrders }) => {
                    this.with_attention = false

                    this.orders = UFBOrders.map(order => {
                        const state = {
                            text: '',
                            event: null,
                        }

                        const book_appointment_data = CONFIG[order.Substate] || CONFIG[order.State]

                        if (book_appointment_data && book_appointment_data.display) {
                            state.text = book_appointment_data.display
                        } else {
                            if (order.Substate) {
                                state.text = `> ${ order.Substate }`
                            } else {
                                state.text = order.State
                            }
                        }

                        const attention = { show: false }

                        const actions = [
                            { action: 'toggle-order-details', title: 'Order details' },
                            { action: 'toggle-order-notes',   title: 'Order notes'   },
                        ]

                        if ((order.IsManual || order.LFC == 'LFCManual') && order.State != 'Completed') {
                            actions.push({ action: 'update-state', title: 'Update State' })
                        }

                        if (order.LFC == 'CHORUS' && !(order.IsManual || order.LFC == 'LFCManual') && book_appointment_data) {
                            state.event = 'book-appointment'

                            actions.push({ action: 'book-appointment', title: book_appointment_data.title })

                            attention.show = true
                            this.with_attention = true
                        }

                        return {
                            uuid: order.UUID,

                            address: order.Address,

                            state,

                            'created-at': order.CreatedAtNanos ? formatDateNano(order.CreatedAtNanos) : '?',

                            attention,

                            actions,

                            remove: {
                                disabled: !this.is_super_admin,
                            },

                            book_appointment_data,
                        }
                    })

                    const { DisplayPageList, PageNumber, TotalItemCount } = PageInfo

                    this.pagination.page = PageNumber
                    this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                    }

                    this.loading = false

                    this.errors = {
                        show: false,
                        message: '',
                    }
                })
                .catch(error => {
                    this.orders = []
                    this.loading = false

                    if (error.response && error.response.status == 404) {
                        return
                    } else {
                        this.errors = {
                            show: true,
                            message: errMessage(error),
                        }
                    }

                })
        },

        fullname(firstname, lastname) {
            const fullname = [firstname, lastname].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        onToggleOrderDetailsSidebar(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'fibre-order-details', params: { uuid: row.uuid } })
            } else {
                for (const key in this.sidebar) {
                    if (key != 'order_details') {
                        this.sidebar[key] = false
                    }
                }

                if (this.sidebar.order_details && (this.service_uuid == row.uuid)) {
                    this.service_uuid = null

                    this.closeSidebar()
                } else {
                    this.sidebar.order_details = true

                    this.service_uuid = row.uuid

                    this.openSidebar()
                }
            }
        },

        onToggleOrderNotesSidebar(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'fibre-order-notes', params: { uuid: row.uuid } })
            } else {
                for (const key in this.sidebar) {
                    if (key != 'order_notes') {
                        this.sidebar[key] = false
                    }
                }

                if (this.sidebar.order_notes && (this.service_uuid == row.uuid)) {
                    this.service_uuid = null

                    this.closeSidebar()
                } else {
                    this.sidebar.order_notes = true

                    this.service_uuid = row.uuid

                    this.openSidebar()
                }
            }
        },

        onUpdateState(device) {
            this.dialogs.devices.update.show = true
            this.dialogs.devices.update.uuid = device.uuid
            this.dialogs.devices.update.spid = this.current_spid
        },

        onUpdateStateConfirm() {
            if (this.service_uuid == this.dialogs.devices.update.uuid) {
                this.closeSidebar()
            }

            this.getFibreOrders()

            this.dialogs.devices.update.show = false
            this.dialogs.devices.update.uuid = null
        },

        onUpdateStateClose() {
            this.dialogs.devices.update.show = false
            this.dialogs.devices.update.uuid = null
        },

        onCancelOrder(device) {
            this.dialogs.devices.remove.show = true
            this.dialogs.devices.remove.uuid = device.uuid
            this.dialogs.devices.remove.spid = this.current_spid
        },

        onCancelOrderConfirm() {
            if (this.service_uuid == this.dialogs.devices.remove.uuid) {
                this.closeSidebar()
            }

            this.getFibreOrders()

            this.dialogs.devices.remove.show = false
            this.dialogs.devices.remove.uuid = null
        },

        onCancelOrderClose() {
            this.dialogs.devices.remove.show = false
            this.dialogs.devices.remove.uuid = null
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.service_uuid = null

            this.$emit('close-sidebar')

            for (const key in this.sidebar) {
                if (this.$refs[key] && typeof this.$refs[key].reset == 'function') {
                    this.$refs[key].reset()
                }
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'is_super_admin',
            '$mobile_size',
        ]),

        sidebar_title() {
            let title = ''

            switch (true) {
                case this.sidebar.order_details : {
                    title = 'Order details'
                } break

                case this.sidebar.order_notes : {
                    title = 'Order notes'
                } break
            }

            return title
        },

        cols() {
            return [
                { key: 'uuid',       title: 'Order ID',                      sort: { field: 'UUID'           }, highlight: true },
                { key: 'address',    title: 'Address',                       sort: { field: 'Address'        }                  },
                { key: 'created-at', title: 'Created At',                    sort: { field: 'CreatedAtNanos' }                  },
                { key: 'state',      title: 'State',      type: 'clickable', sort: { field: 'State'          }, multiline: true },

                ...(this.with_attention
                    ? [{ key: 'attention', attention: 'Attention' }]
                    : []
                ),

                { actions: [], behavior: 'detached', },

                { key: 'remove', action: 'cancel-order', icon: 'remove', behavior: 'detached' },
            ]
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.page-fibre-orders {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .fibre-orders-table {
        @include table-cols-width((335px, 400px, 160px, 150px, 48px, 48px), true, 2);

        .attention {
            color: #7f1f1f;
        }

        &.with-attention {
            @include table-cols-width((335px, 400px, 160px, 150px, 48px, 48px, 48px), true, 2);
        }
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-fibre-orders {
        .fibre-orders-table {
            @include table-cols-width((300px, 350px, 130px, 150px, 48px, 48px), true, 2);

            &.with-attention {
                @include table-cols-width((300px, 350px, 130px, 150px, 48px, 48px, 48px), true, 2);
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-fibre-orders {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .fibre-orders-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>