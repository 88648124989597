<template>
    <app-dialog-info
        class="app-dialog-order-details-editing"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading" v-if="heading">{{ heading }}</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <app-error v-model="errors.show" :message="errors.message" />

            <div class="form-group">
                <div class="form-controls">
                    <app-dropdown-select
                        v-if="field == 'ProductAvailabilityUUID'"

                        v-model="local_value"
                        :options="plans"

                        key-value="UUID"
                        key-title="Title"

                        label="Plan"

                        :multiline="true"
                    />

                    <app-input v-else-if="field == 'SiteContactName'" v-model="local_value" label="Site Contact Name" :required="true" :error="errors.fields.SiteContactName" @change="errors.fields.SiteContactName = null" />
                    <app-input v-else-if="field == 'SiteContactPhone'" v-model="local_value" label="Site Contact Phone" :required="true" :error="errors.fields.SiteContactPhone" @change="errors.fields.SiteContactPhone = null" />
                    <app-input v-else-if="field == 'NotificationName'" v-model="local_value" label="Notification Name" :error="errors.fields.NotificationName" @change="errors.fields.NotificationName = null" />
                    <app-input v-else-if="field == 'NotificationPhoneNumber'" v-model="local_value" label="Notification Phone Number" :error="errors.fields.NotificationPhoneNumber" @change="errors.fields.NotificationPhoneNumber = null" />
                    <app-input v-else-if="field == 'NotificationEmail'" v-model="local_value" label="Notification Email" :error="errors.fields.NotificationEmail" @change="errors.fields.NotificationEmail = null" />

                    <app-radio-buttons v-else-if="field == 'ServiceType'" v-model="local_value" :items="service_types" :inline="true" />
                    <app-input v-else-if="field == 'LANIPRange'" v-model="local_value" label="LAN IP Range" :error="errors.fields.LANIPRange" @change="errors.fields.LANIPRange = null" />
                    <app-input v-else-if="field == 'VRFDetails'" v-model="local_value" label="VRF Details" :error="errors.fields.VRFDetails" @change="errors.fields.VRFDetails = null" />
                    <app-input v-else-if="field == 'AdditionalDetails'" v-model="local_value" label="Additional Details" :error="errors.fields.AdditionalDetails" @change="errors.fields.AdditionalDetails = null" />
                    <app-radio-buttons v-else-if="field == 'DHCPScope'" v-model="local_value" :items="dhcp_scope_options" :inline="true" />
                    <app-input v-else-if="field == 'IPHelperDetails'" v-model="local_value" label="IP Helper Details" :error="errors.fields.IPHelperDetails" @change="errors.fields.IPHelperDetails = null" />
                    <app-input v-else-if="field == 'WANIP'" v-model="local_value" label="WAN IP" :error="errors.fields.WANIP" @change="errors.fields.WANIP = null" />

                    <app-input v-else-if="field == 'Address'" v-model="local_value" label="Address" :error="errors.fields.Address" @change="errors.fields.Address = null" />
                </div>
            </div>

            <div class="btns">
                <button class="btn btn-primary" :disabled="!is_changed" @click="confirm">Confirm</button>
                <button class="btn btn-primary btn-cancel" @click="close">Cancel</button>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'
import appRadioButtons from '@/components/app-radio-buttons'

import BroadbandValidator from '@/validators/broadband-validator'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:    { required: true,                        },
        maxWidth: { type: [Number, String], default: 610   },

        field: { required: true },
        originalValue: { required: true },

        order: { required: true },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appInput,
        appDropdownSelect,
        appRadioButtons,
    },

    data() {
        return {
            local_value: null,
            plans: {},

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.local_value = this.originalValue

            if (this.field == 'ProductAvailabilityUUID') {
                this.getPlans()
            }
        },

        getPlans() {
            if (!Object.keys(this.plans).length) {
                this.loading = true

                const params = {
                    SPID: this.current_spid,
                    GetAllPlans: true,
                }

                this.$store.dispatch('api_ufbprod/GetUFBPlans', params)
                    .then(({ UFBPlans }) => {
                        const plans = {}

                        for (let i = 0, len = UFBPlans.length; i < len; i++) {
                            plans[UFBPlans[i].UUID] = {
                                UUID: UFBPlans[i].UUID,
                                Title: `${ UFBPlans[i].Name } ($${ UFBPlans[i].Cents % 100 == 0 ? UFBPlans[i].Cents / 100 : (UFBPlans[i].Cents / 100).toFixed(2) })`,
                            }
                        }

                        this.plans = plans

                        console.log(JSON.parse(JSON.stringify(this.plans)))

                        this.loading = false
                    })
                    .catch(error => {
                        this.$store.dispatch('addToast', {
                            message: errMessage(error),
                            type: 'error',
                            delay: 5000,
                        })

                        this.loading = false
                    })
            }
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                SiteContactName:         { validator: BroadbandValidator, rule: 'fullname', message: 'Please, enter name'               },
                SiteContactPhone:        { validator: BroadbandValidator, rule: 'phone',    message: 'Please, enter valid phone number' },
                NotificationName:        { validator: BroadbandValidator, rule: 'fullname', message: 'Please, enter name'               },
                NotificationPhoneNumber: { validator: BroadbandValidator, rule: 'phone',    message: 'Please, enter valid phone number' },
                NotificationEmail:       { validator: BroadbandValidator, rule: 'email',    message: 'Please, enter valid email'        },

                Address: { validator: BroadbandValidator, rule: 'address', message: 'Please, enter address' },
            }

            BroadbandValidator.mixin({
                phone: {
                    required: true,
                },
            })

            if (this.field in fields) {
                if (fields[this.field].validator.isRuleExists(fields[this.field].rule)) {
                    if (fields[this.field].validator.isInvalid(fields[this.field].rule, this.local_value, fields[this.field].message)) {
                        this.errors.fields[this.field] = fields[this.field].validator.getError()

                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    SPID: this.current_spid,
                    UUID: this.order.UUID,

                    ...this.order,

                    [this.field]: this.local_value,
                }

                console.log('params', params)

                this.$store.dispatch('api_ufbprod/UpdateUFBOrder', params)
                    .then((response) => {
                        console.log('response', response)

                        this.$emit('confirm')

                        this.loading = false
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },

        close() {
            this.$emit('close')
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },

        is_changed() {
            return this.originalValue != this.local_value
        },

        heading() {
            let heading = null

            switch (this.field) {
                case 'ProductAvailabilityUUID': { heading = 'Change plan' } break

                case 'SiteContactName':         { heading = 'Change Site Contact Name'         } break
                case 'SiteContactPhone':        { heading = 'Change Site Contact Phone'        } break
                case 'NotificationName':        { heading = 'Change Notification Name'         } break
                case 'NotificationPhoneNumber': { heading = 'Change Notification Phone Number' } break
                case 'NotificationEmail':       { heading = 'Change Notification Email'        } break

                case 'ServiceType':       { heading = 'Change Service Type'       } break
                case 'LANIPRange':        { heading = 'Change LAN IP Range'       } break
                case 'VRFDetails':        { heading = 'Change VRF Details'        } break
                case 'AdditionalDetails': { heading = 'Change Additional Details' } break
                case 'DHCPScope':         { heading = 'Change DHCP Scope'         } break
                case 'IPHelperDetails':   { heading = 'Change IP Helper Details'  } break
                case 'WANIP':             { heading = 'Change WAN IP'             } break

                case 'Address': { heading = 'Change address' } break
            }

            return heading
        },

        service_types() {
            return [
                {
                    value: 'Unmanaged',
                    label: 'Unmanaged Service',
                },
                {
                    value: 'Managed',
                    label: 'Managed Service',
                },
            ]
        },

        dhcp_scope_options() {
            return [
                {
                    value: true,
                    label: 'Yes',
                },
                {
                    value: false,
                    label: 'No',
                },
            ]
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-order-details-editing {
    .app-dialog-body {
        padding: 80px 36px;

        .form-group {
            width: 100%;
        }
    }
}
</style>